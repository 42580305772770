<template>
  <div class="contents-area not-found-contents">
    <div
      class="section notfound-screen"
      :style="
        'background-image: url(//' +
        serverLink +
        '/upload/image/egrul/404.jpg' +
        ')'
      "
    >
      <div class="container">
        <div class="notfound-text">
          <h1>{{ translations("PageNotFound") }}</h1>
          <p>{{ translations("PageNotFoundText") }}</p>
          <router-link to="/" class="btn btn-blue btn-big">
            {{ translations("BackToHome") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters } from "vuex";

export default {
  name: "PageNotFound",
  components: {},
  metaInfo() {
    return {
      title: this.translations("PageNotFound"),
      meta: [{ name: "robots", content: "noindex" }],
    };
  },
  data: () => ({
    serverLink: VUE_APP_BACK_URL,
  }),
  computed: {
    ...mapGetters(["translations"]),
  },
};
</script>